/*
 * @Author: your name
 * @Date: 2020-11-19 15:02:30
 * @LastEditTime: 2021-02-20 17:06:46
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\api\Channel.js
 */
//渠道
import request from '@/utils/request'

// 删除
export function ChannelDelete(data) {
  return request({
    url: '/baseAdmin/customer-channel/delete',
    method: 'post',
    data,
  })
}
// 改变渠道价格
export function ChannelPrice(data) {
  return request({
    url: '/baseAdmin/customer-channel/price-status',
    method: 'post',
    data,
  })
}
//快速创建 保存
export function ChannelFast(data) {
  return request({
    url: '/baseAdmin/customer-channel/create-fast',
    method: 'post',
    data,
  })
}
//快速创建 列表
export function ChannelFastList(data) {
  return request({
    url: '/baseAdmin/customer-channel/fast-list',
    method: 'post',
    data,
  })
}
//编辑入口 详情
export function ChannelQuDetail(data) {
  return request({
    url: '/baseAdmin/customer-channel/detail',
    method: 'post',
    data,
  })
}
// 编辑入口 保存
export function ChannelUpdate(data) {
  return request({
    url: '/baseAdmin/customer-channel/update',
    method: 'post',
    data,
  })
}
// 获取商品列表
export function ChannelList(data) {
  return request({
    url: '/baseAdmin/customer-channel/index',
    method: 'post',
    data,
  })
}

// 添加 保存
export function ChannelCreate(data) {
  return request({
    url: '/baseAdmin/customer-channel/create',
    method: 'post',
    data,
  })
}
