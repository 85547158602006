<template>
  <!-- 渠道 zz201110-->
  <div class="wrapper">
    <!--  -->
    <el-row type="flex" class="row-bg btncls" justify="end">
      <el-col :span="2">
        <el-button @click="Quickadd">快速添加</el-button>
      </el-col>
      <el-col :span="2">
        <el-button @click="addEdit">添加</el-button>
      </el-col>
    </el-row>
    <!--  -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="批量添加渠道类型"
      :visible.sync="k_dialogFormVisible"
      class="qudao"
    >
      <el-form :model="k_form">
        <el-row :gutter="20">
          <el-col :span="20" :offset="2">
            <el-checkbox-group v-model="k_form.checkList">
              <el-checkbox
                v-for="lis in k_form.listcheck"
                :key="lis.id"
                :label="lis.id"
              >
                {{ lis.channel_name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="QuickaddcreateFast">保存</el-button>
        <el-button @click="k_dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加渠道"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-row :gutter="20">
          <el-col :span="16" :offset="2">
            <el-form-item label="*渠道名称：" :label-width="formLabelWidth">
              <el-input
                v-model="form.channel_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="描述：" :label-width="formLabelWidth">
              <el-input
                v-model="form.channel_remark"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="商品需设置渠道价格："
              :label-width="formLabelWidth"
            >
              <!-- hhy修改状态 -->
              <el-switch
                v-model="form.is_channel_price"
                :active-value="0"
                :inactive-value="1"
                active-color="#409EFF"
                inactive-color="#dcdfe6"
              ></el-switch>
            </el-form-item>
            <el-form-item label="*状态：" :label-width="formLabelWidth">
              <el-radio-group v-model="form.is_closed">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clientlevelCreate_fun(saveType)">
          保存
        </el-button>
        <el-button @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-table
      v-loading="loading"
      stripe
      :data="tableData"
      border
      style="width: 100%"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
        align="center"
      ></el-table-column> -->
      <el-table-column label="排序" width="80">
        <template #default="{ row }">
          <el-input
            v-model="row.sort_order"
            placeholder="排序"
            @blur="handlerblur(row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="channel_name"
        label="渠道名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="channel_remark" label="描述"></el-table-column>
      <el-table-column label="渠道价格开关" prop="is_channel_price">
        <template slot-scope="scope">
          <!-- <el-switch
            v-model="scope.row.is_channel_price"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwith(scope.row)"
          >
            >
          </el-switch> -->
          <el-switch
            v-model="scope.row.is_channel_price"
            :active-value="0"
            :inactive-value="1"
            @change="changeSwith(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="is_closed_text" label="状态"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
            编辑
          </el-button>
          <el-button type="text" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import {
    ChannelDelete,
    ChannelPrice,
    ChannelFast,
    ChannelFastList,
    ChannelQuDetail,
    ChannelUpdate,
    ChannelList,
    ChannelCreate,
  } from '@/api/Channel'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Channel',
    components: {},
    data() {
      return {
        saveType: 0, //2,编辑入口(保存) ，0，添加入口（保存）=[!2，不显示“保存并新增”]
        loading: true,
        listdata: {
          pageNo: 1,
          pageSize: 1000,
        },
        tableData: [],
        dialogTableVisible: false,
        dialogFormVisible: false,
        k_dialogTableVisible: false,
        k_dialogFormVisible: false,
        form: {
          channel_name: '',
          channel_remark: '',
          is_channel_price: 1,
          is_closed: 0,
        },
        k_form: {
          checkList: [],
          listcheck: [],
        },
        formLabelWidth: '160px',
      }
    },
    computed: {},
    created() {
      this.fetchData()
    },
    methods: {
      //排序
      handlerblur(row) {
        postAction('/baseAdmin/customer-channel/sort', {
          id: row.id,
          sort: row.sort_order,
        })
          .then((res) => {
            console.log(res, '排序')
            this.fetchData()
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 快速添加保存
      QuickaddcreateFast() {
        var that = this
        if (that.k_form.checkList.length == 0) {
          this.$alert('<p>请选择渠道类型，至少一个</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        } else {
          console.log(that.k_form.checkList)
          var sCheckList = that.k_form.checkList.toString()
          console.log(sCheckList)
          ChannelFast({ ids: sCheckList }).then((d) => {
            if (d.code == 200) {
              that.fetchData()
              that.k_dialogFormVisible = false
            }
          })
        }
      },
      // 快速添加
      Quickadd() {
        var that = this
        that.k_form.checkList = []
        that.k_dialogFormVisible = true
        ChannelFastList().then((d) => {
          if (d.code == 200) {
            that.k_form.listcheck = d.data
          }
        })
      },
      // 添加入口
      addEdit() {
        var that = this
        that.saveType = 0
        that.dialogFormVisible = true
        that.form = {
          channel_name: '',
          channel_remark: '',
          is_channel_price: 1,
          is_closed: 0,
        }
      },
      //更改开关状态
      changeSwith(row) {
        console.log(row, '更改开关状态')
        postAction('/baseAdmin/customer-channel/price-status', { id: row.id })
          .then((res) => {
            console.log(res)
            this.fetchData()
          })
          .catch((err) => {
            console.log(err, '')
          })
        // var that = this
        // ChannelPrice({ id: row.id }).then((d) => {
        //   if (d.code == 200) {
        //     console.log(d, '')
        //     // that.fetchData()
        //   }
        // })
      },
      handleEdit(index, row) {
        console.log(index, row)
        var that = this
        ChannelQuDetail({ id: row.id }).then((d) => {
          if (d.code == 200) {
            that.form = d.data
          }
        })
        that.saveType = 2
        that.dialogFormVisible = true
      },
      handleDelete(index, row) {
        console.log(index, row)
        var that = this
        that
          .$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            ChannelDelete({ id: row.id }).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          })
          .catch(() => {
            that.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      // 获取表格数据
      async fetchData() {
        let { data, totalCount } = await ChannelList(this.listdata)
        console.log(data)
        this.tableData = data
        this.loading = false
      },
      clientlevelCreate_fun(types) {
        //添加入口1，保存并新增 0，保存
        // 编辑入口 2，保存
        var that = this
        if (that.form.channel_name == '') {
          this.$alert('<p>请输入渠道名称</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        }
        //  else if (that.form.channel_remark == '') {
        //   // this.$alert('<p>请输入描述</p>', '提示', {
        //   //   dangerouslyUseHTMLString: true,
        //   // })
        // }
        else if (that.form.is_closed == 3) {
          this.$alert('<p>请选择状态</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        } else {
          if (types == 2) {
            ChannelUpdate(that.form).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.dialogFormVisible = false
              }
            })
          } else {
            ChannelCreate(that.form).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.dialogFormVisible = false
              }
            })
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    padding: 20px;
  }
  .btncls {
    margin: 10px 0;
  }
  .qudao .el-checkbox-group label {
    line-height: 30px;
  }
</style>
